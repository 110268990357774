@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.container {
    min-width: 100vw;
    min-height: 8vh;
    max-height: 8vh;
    
    background-color: $color-prompt;

    @include flex-row-between-center;

    border-bottom: 1px solid $violet-secondary;
}

.navbar_main{
    width: 100%;
    height: 100%;
    @include flex-row-between-center;

    padding: 0rem 2rem;
    text-decoration: none;

}

.navbar_main_left{
    margin: 1rem;
    @include flex-row-center-center;
    gap: 0.5rem;
    cursor: pointer;
    
    & div{
        font-size: 1.4rem;
    }

}
.navbar_main_right{
    margin: 1rem;
    @include flex-row-end-center;
    cursor: pointer;

}