@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.full_screen_start_center{
    min-width: 100vw;
    min-height: 100vh;

    @include flex-column-start-center;
    background-color: $violet-primary;

    overflow-y: scroll;
}


.full_screen_center_center{
    min-width: 100vw;
    min-height: 100vh;

    @include flex-column-center-center;
    background-color: $violet-primary;

    overflow-y: scroll;
}
