
.app {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-rows: 73px 1fr;
  grid-template-columns: min-content 1fr;
  overflow: hidden;
}


input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.4);
}

@media screen and (max-width: 500px) {
  .app {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  }

}